@import "./../theme-bootstrap";

.link-based-offer {
  @include clearfix;
  padding: 20px;
  &__image {
    float: left;
  }
  &__description {
    float: left;
    margin-left: 20px;
  }
  &__header {
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__message {
  }
}
